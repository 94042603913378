.exhibitor {
	@include span(12 no-gutters);
	h1,h4 {
		margin: 0;
	}
	padding-top: 2rem;
	padding-bottom: 1rem;
	@include min-screen(768px) {
		position: relative;
		&:first-child {
		padding-top: 0;
		}
		&:after {
			content: '';
			position: absolute;
			width: 98%;
			height: 1px;
			bottom: 0;
			left: 2%;
			background: #ddd;
		}
	}
	small {
		display: block;
		font-weight: bold;
		text-transform: uppercase;
		margin-bottom: .5rem;
		color: #999;
	}
		@include max-screen(768px) {
			text-align: center;
			border: 1px solid #ddd;
			box-shadow: 2px 2px 0px 0px rgba(0,0,0,.1);
			padding: 2rem 1rem;
			margin-bottom: 2rem;
		}
	.exhibitor-logo {
		@include span(3);
		position: relative;
		img {
			max-width: 80%;
		}
		@include max-screen(768px) {
			@include span(12);
			padding-bottom: 1rem;
			img {
				max-width: 50%;
			}
		}
	}
	.exhibitor-company {
		@include span(3);
		padding-bottom: 1rem;
		@include max-screen(768px) {
			@include span(12);
		}
	}
	.exhibitor-contact {
		@include span(3);
		padding-bottom: 1rem;
		span {
			display: block;
		}
		@include max-screen(768px) {
			@include span(12);
		}
	}
	.exhibitor-stand {
		@include span(3);
		h1 {
			display: inline-block;
			padding: .5rem 1.25rem;
			border: 2px solid #111;
		}
		@include max-screen(768px) {
			@include span(12);
		}
	}
}

.exhibitor-table-header {
	@include span(12);
	padding-top: 1.5rem;
	border-top: 1px solid #ddd;
		@include max-screen(768px) {
			display: none;
		}
	h4 {
		paddding: 0;
		margin: 0;
	}
	.exhibitor {
		padding: 0;
		border: 0;
	}
	.exhibitor-company {
		@include push(3);
	}
}