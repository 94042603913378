.signin-form {
    @include span(12);
    background: #fff;
    margin-top: 2rem;
    @include min-screen(768px) {
@include span(8);
    @include pre(2);
    }
    .signin-form-inner {
        padding: 1rem;
        .page-header {
            text-align: center;
        }
        .btn-group-justified {
        	margin-bottom: 2rem;
        	.btn-default {
        		border-radius: 0px;
		&:first-child{
			border-top-left-radius: 2px;
			border-bottom-left-radius: 2px;
		}
		&:nth-child(2) {
			border-left: 0px;
			border-right: 0px;
		}
		&:last-child{
			border-top-right-radius: 2px;
			border-bottom-right-radius: 2px;
		}
        	}
        }
    }
}

.sign-up-link {
    @include span(12);
    margin-top: 2rem;
    text-align: center;
    p {
        margin: 0;
    }
    a {
        display: inline-block;
        margin-top: 1rem;
    }
}

.speaker-profile-image {
    @include span(12);
    @include min-screen(768px) {
        @include span(4);
    }
}

.speaker-profile-image-inner {
    @include maintain-ratio(4 4);
    border: 1px solid #eee;
    background: #eee;
    margin-bottom: vr(ms(0))/2;
    overflow: hidden;
    border-radius: 2px;
    background: #fff;
    img {
        min-width: 100%;
        padding: 1rem;
        overflow: hidden;
    }
}

.speaker-profile-info {
    @include span(12);
    word-wrap: break-word;
    @include min-screen(768px) {
        @include span(8);
        @include omega();
    }
}

.partner-profile-image {
    @include span(12);
    @include min-screen(768px) {
        @include span(4);
    }
}

.partner-profile-image-inner {
    @include maintain-ratio(4 4);
    border: 1px solid #eee;
    background: #eee;
    margin-bottom: vr(ms(0))/2;
    overflow: hidden;
    border-radius: 2px;
    background: #fff;
    position: relative;
    img {
        position: absolute;
        top: 50%;
        min-width: 100%;
        padding: 1rem;
        overflow: hidden;
        transform: translateY(-50%);
    }
}

.partner-profile-info {
    @include span(12);
    @include min-screen(768px) {
        @include span(8);
        @include omega();
    }
}
