$ms-base: 1rem;
$ms-ratio: $major-third;

@font-face {
  font-family: 'Scandia';
  src: url('../fonts/ScandiaWebLight.eot'); /* IE9 Compat Modes */
  src: url('../fonts/ScandiaWebLight.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/ScandiaWebLight.woff') format('woff'); /* Pretty Modern Browsers */
       font-weight: 200;
       font-style: normal;
}

@font-face {
  font-family: 'Scandia';
  src: url('../fonts/ScandiaWebRegular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/ScandiaWebRegular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/ScandiaWebRegular.woff') format('woff'); /* Pretty Modern Browsers */
       font-weight: normal;
       font-style: normal;
}

@font-face {
  font-family: 'Scandia';
  src: url('../fonts/ScandiaWebMedium.eot'); /* IE9 Compat Modes */
  src: url('../fonts/ScandiaWebMedium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/ScandiaWebMedium.woff') format('woff'); /* Pretty Modern Browsers */
       font-weight: 500;
       font-style: normal;
}

@font-face {
  font-family: 'Scandia';
  src: url('../fonts/ScandiaWebBold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/ScandiaWebBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/ScandiaWebBold.woff') format('woff'); /* Pretty Modern Browsers */
       font-weight: bold;
       font-style: normal;
}

html {
    @include vr-baseline(ms(0), 1.5);
}

body {
    font-family: "Scandia";
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    line-height: 1.5;
    font-size: 87.5%;
    font-weight: normal;
}

h1 {
    fs: ms(4);
    line-height: 1.25;
    @include iphone5(portrait) {
    	fs: (0.725 * ms(4))
    }
    @include iphone6(portrait) {
    	fs: (0.825 * ms(4))
    }
}

h2 {
    fs: ms(3);
    @include iphone5(portrait) {
    	fs: (0.725 * ms(3))
    }
    @include iphone6(portrait) {
    	fs: (0.825 * ms(3))
    }
}

h3 {
    fs: ms(2);
    @include iphone5(portrait) {
    	fs: (0.725 * ms(2))
    }
    @include iphone6(portrait) {
    	fs: (0.825 * ms(2))
    }
}

h4 {
    fs: ms(1);
    margin: vr(ms(1)/2) 0 0 0;
    @include iphone5(portrait) {
    	fs: (0.825 * ms(1))
    }
    @include iphone6(portrait) {
    	fs: (0.825 * ms(1))
    }
}

h5 {
    fs: ms(0);
}

p {
    fs: ms(0);
    margin: 0 0 vr(ms(0)/2) 0;
}

.bigtext {
	color: rgba(#111,1);
    opacity: 0;
	> div {
		text-transform: uppercase;
		line-height: 1;
		margin: 0;
		font-weight: 900;
	}
}
