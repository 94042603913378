@import "shares";

$color: #acacac;
$hover-color: darken($color, 10%);
$count-box-bg: #f5f5f5;
$count-arrow-size: .4rem;
$count-arrow-offset: $count-arrow-size - .1rem;
$round-size: .0rem;
$transition: background 200ms ease-in-out, color 200ms ease-in-out, border-color 200ms ease-in-out;

.jssocials-share-link {
    padding: .5rem .6rem;
    border-radius: $round-size;
    border: 2px solid $color;
    color: $color;
    transition: $transition;

    &:hover, &:focus, &:active {
        border: 2px solid $hover-color;
        color: $hover-color
    }
}

.jssocials-share-count-box {
    position: relative;
    height: 1.5rem;
    padding: 0 .3rem;
    margin-left: $count-arrow-offset;
    background: $count-box-bg;
    border-radius: $round-size;
    transition: $transition;

    &:hover {
        background: darken($count-box-bg, 5%);

        &:after {
            border-color: transparent darken($count-box-bg, 5%) transparent transparent;
        }
    }

    &:after {
        content: "";
        display: block;
        position: absolute;
        top: .75rem - $count-arrow-size;
        left: -$count-arrow-offset;
        width: 0;
        height: 0;
        border-width: $count-arrow-size $count-arrow-size $count-arrow-size 0;
        border-style: solid;
        border-color: transparent $count-box-bg transparent transparent;
        transform: rotate(360deg);
        transition: $transition;
    }

    .jssocials-share-count {
        line-height: 1.7rem;
        color: $color;
        font-size: 1rem;
    }
}
