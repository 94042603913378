.featured-box {
    @include span(4);
    background: linear-gradient(0deg, lighten(#F36B39, 35%), lighten(#F36B39, 45%));
    padding: 1rem;
    text-align: center;
    .featured-box-image {
        @include span(12);
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
        img {
            flex: 0 1 auto;
            min-height: 72px;
            height: 72px;
        }
    }
    .featured-box-text {
        h3 {
            max-width: 100%;
        }
    }
    a {
        background: #F36B39 !important;
        width: 100%;
        text-align: center;
        padding: 1rem 0;
        border-radius: 2px;
        font-size: 16px;
        &:hover {
            background: darken(#F36B39, 5%) !important;
        }
    }
}

@media screen and (max-width: 768px) {
    .featured-box {
        @include span(12);
        position: relative;
        margin-bottom: 24px;
        .featured-box-image {
            display: inline-block;
            float: left;
            width: 20%;
            margin: 0;
            display: none;
        }
        .featured-box-text {
            display: inline-block;
            float: left;
            width: 100%;
            margin: 0;
            h3 {
                display: block;
                margin-top: 0;
                clear: both;
            }
            p {
                display: block;
                clear: both;
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1070px) {
    .featured-box {
        .featured-box-text {
            h3 {
                max-width: 10ch;
                margin: 0 auto;
            }
        }
    }
}