$pushy-width: 220px;

.pushy{
    position: fixed;
    width: $pushy-width;
    height: 100%;
    top: 0;
    z-index: 9999;
    background: #111;
    font-size: ms(0);
    font-weight: normal;
    list-style: none;
    //-webkit-box-shadow: inset -10px 0 6px -9px rgba(0, 0, 0, .7);
    //-moz-box-shadow: inset -10px 0 6px -9px rgba(0, 0, 0, .7);
    //box-shadow: inset -10px 0 6px -9px rgba(0, 0, 0, .7);
    overflow: auto;
    -webkit-overflow-scrolling: touch; /* enables momentum scrolling in iOS overflow elements */
}

.pushy a{
    display: block;
    color: #fff;
    padding: 15px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    border-top: 1px solid rgba(255, 255, 255, .1);
    text-decoration: none;
}

.pushy a:hover{
    background: transparent;
    color: #FFF;
}

/* Menu Movement */

.pushy-left{
    -webkit-transform: translate3d(-$pushy-width,0,0);
    -moz-transform: translate3d(-$pushy-width,0,0);
    -ms-transform: translate3d(-$pushy-width,0,0);
    -o-transform: translate3d(-$pushy-width,0,0);
    transform: translate3d(-$pushy-width,0,0);
}

.pushy-open{
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    -o-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    .container {
        @include container(90%);
        color: #fff;
    }
}

.container-push, .push-push{
    -webkit-transform: translate3d($pushy-width,0,0);
    -moz-transform: translate3d($pushy-width,0,0);
    -ms-transform: translate3d($pushy-width,0,0);
    -o-transform: translate3d($pushy-width,0,0);
    transform: translate3d($pushy-width,0,0);
}

/* Menu Transitions */

.pushy, #container, .push{
    -webkit-transition: -webkit-transform .2s cubic-bezier(.16, .68, .43, .99);
    -moz-transition: -moz-transform .2s cubic-bezier(.16, .68, .43, .99);
    -o-transition: -o-transform .2s cubic-bezier(.16, .68, .43, .99);
    transition: transform .2s cubic-bezier(.16, .68, .43, .99);
}

/* Site Overlay */

.site-overlay{
    display: none;
}

.pushy-active .site-overlay{
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9998;
    background-color: rgba(0,0,0,0.5);
    -webkit-animation: fade 500ms; 
    -moz-animation: fade 500ms; 
    -ms-animation: fade 500ms;
    -o-animation: fade 500ms;
    animation: fade 500ms;
}

@keyframes fade{
    0%   { opacity: 0; }
    100% { opacity: 1; }
}
