/*===============================
=            Vendor            =
===============================*/

@import "vendor/iconic";
@import "vendor/bootstrap_variables";
@import "bower_components/flag-icon-css/sass/flag-icon";
@import "bower_components/jssocials/styles/jssocials";
@import "bower_components/jssocials/styles/jssocials-theme-plain";

// bower:scss
@import "bower_components/susy/sass/_susy.scss";
@import "bower_components/sassy-maps/sass/_sassy-maps.scss";
@import "bower_components/breakpoint-sass/stylesheets/_breakpoint.scss";
@import "bower_components/modular-scale/stylesheets/_modular-scale.scss";
@import "bower_components/vertical-rhythmic/_vertical-rhythmic.scss";
@import "bower_components/sass-mediaqueries/_media-queries.scss";
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "bower_components/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox.scss";
// endbower

/*===============================
=            Globals            =
===============================*/

@import "globals/aliases";
@import "globals/mixins";
@import "globals/typography";
@import "globals/grid";

/*===============================
=           Components          =
===============================*/

@import "components/c_mobile-nav";
@import "components/c_mobile-usernav";
@import "components/c_top-bar";
@import "components/c_header";
@import "components/c_speakers";
@import "components/c_news";
@import "components/c_newsletter-signup";
@import "components/c_partners";
@import "components/c_signedin";
@import "components/c_user-navigation";
@import "components/c_page";
@import "components/c_footer";
@import "components/c_article";
@import "components/c_media_and_social";
@import "components/c_venue_and_hotels";
@import "components/c_home";
@import "components/c_featuredboxes";
@import "components/c_social_media";
@import "components/c_programme";
@import "components/c_exhibitors";

/*===============================
=            Modules            =
===============================*/

@import "modules/m_buttons";
@import "modules/m_video";

.mainbody {
	padding-top: vr(ms(0));
	@include container(80%);
	@include max-screen(768px) {
		@include container(92.5%);
	}
}