

.newsletter-signup {
    background: #111;
    color: #fff;
    height: auto;
    line-height: normal;
    margin: 0;
    padding: gutter()*2 0;
    border-top: 1px solid #eee;
    h4 {
        margin: 0;
        padding: 0;
    }
}

.newsletter-signup-top {
    background: #f7f7f7;
    border-bottom: 1px solid #eee;
    padding: gutter()/2 0;
    margin-bottom: 3rem;
    position: relative;
    .newsletter-signup-top-header {
        position: absolute;
        top: -30px;
        left: 12.5% - gutter()/3;
        z-index: 900;
        //transform: translateX(-50%);
        background: #f7f7f7;
        line-height: 1;
        margin: 0px;
        padding: .75rem 1.25rem;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        h5 {
            margin: 0;
        }
    }
    .newsletter-signup-form {
        form {
        .form-group {
            input {
                border: 2px solid #eee;
            }
        }
    }
    }
}

.newsletter-signup-heading {
    @include span(12);
    margin-bottom: vr(ms(1));
}

.newsletter-signup-form {
    @include span(12);
    overflow: hidden;
    padding-top: 0rem;
    padding-bottom: 0rem;
    .form-inline {
        display: flex;
        flex-wrap: wrap;
        >div {
            display: flex;
            flex-wrap: wrap;
            flex-grow: 1;
            line-height: 1;
            position: relative;
            input {
                flex-grow: 1;
                margin-right: gutter();
            }
            h5 {
                flex-grow: 1;
            }
        }
        button {
            display: flex;
            border: 0;
            border-radius: 2px;
            text-align: center;
            padding: 0 1.5rem;
            justify-content: center;
            -webkit-justify-content: space-between;
            background: desaturate(lighten(red, 5%), 40%);
            color: #fff;
            font-weight: 500;
            &:hover {
                background: desaturate(darken(red, 0%), 40%);
            }
        }
    }
    @include max-screen(768px) {
        .form-inline {
            display: block;
            >div, button {
                display: block;
                @include span(12 no-gutters);
            }
            button {
                line-height: 36px;
            }
        }
    }
}
