.page {
    padding-top: vr(ms(4)) + vr(ms(1));
    @include max-screen(768px) {
        padding-top: vr(ms(4));
    }
}

.article-page {
    padding-top: vr(ms(4)) + vr(ms(1)) + vr(ms(0));
    @include max-screen(768px) {
        padding-top: vr(ms(4)) + vr(ms(0));
    }
}

.page-header {
    //background: #f7f7f7 url('../images/ps_neutral.png');
    background: #fff;
    color: #111;
    border-bottom: 1px solid #eee;
    padding: vr(ms(0)) 0 vr(ms(0)) 0;
    margin-bottom: vr(ms(4));
    //transform: rotate(-1deg);
    width: 100%;
    overflow: hidden;
    .page-header-inner {
        @include span(12);
    }
    h2 {
        //transform: rotate(1deg);
        font-weight: 500;
        margin-bottom: .75rem;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
    }
    @include max-screen(768px) {
        border-top: none;
        text-align: center;
    }
}

.page-header-submenu {
    transition: background 350ms ease-in-out;
    ul {
        list-style: none;
        padding: 0;
        @include span(12);
        font-weight: 500;
        text-transform: uppercase;
        font-size: $font-size-h6;
        li {
            display: inline-block;
            a {
                padding: ms(-6) ms(-3);
                display: block;
                color: #666;
                &:hover {
                    color: #111;
                }
            }
        }
        li.active {
            a {
                background: #fff;
                color: #111;
                border: 2px solid #ccc;
            }
        }
    }
    @include max-screen(768px) {
        ul {
            display: flex;
            li {
                flex-grow: 1;
                flex-basis: 0;
                text-align: center;
                margin-right: 1rem;
                &:last-child {
                    margin-right: 0;
                }
                a {
                    border: 2px solid #eee;
                    &:hover {
                        border-color: #ccc;
                    }
                }
            }
        }
    }
}

.page-header-submenu.static {
    background: #eee;
    position: fixed;
    width: 100%;
    top: vr(ms(4)) + vr(ms(1));
    left: 0;
    transition: transform 350ms ease-in-out;
    z-index: 999;
    ul {
        margin: .5rem gutter();
        li {
            a {
                color: #666;
                &:hover {
                    color: #111;
                }
            }
        }
        li.active {
            a {
                background: none;
                border-color: #111;
                background: #111;
                color: #fff;
            }
        }
    }
    @include max-screen(768px) {
        top: vr(ms(4));
        background: #111;
        ul {
            li {
                a {
                    border-color: #111;
                    color: #fff;
                }
            }
            li.active {
                a {
                    border-color: #fff;
                }
            }
        }
    }
}

.page-header-submenu-inner.static {
    @include container (80%);
    @include max-screen(768px) {
        @include container(92.5%);
    }
}
