article.article {
    width: 100%;
    .article-header {
        @include span(12 nest);
        .article-title {
            @include span(12);
            p.lead {
                font-weight: normal;
                font-size: $font-size-h4;
                margin-bottom: 2rem;
                margin-top: 1rem;
                max-width: span(10);
                @include screen(0px, 768px) {
                    max-width: span(12);
                }
            }
        }
        h1 {
            max-width: span(10);
            margin-bottom: .5rem;
            @include screen(0px, 768px) {
                max-width: span(12);
            }
            @include screen(768px, 1140px) {
                max-width: span(11);
            }
        }
        .article-main-img-and-meta {
            @include span(12 nest);
        }
        .article-image {
            @include span(8);
            @include screen(0px, 768px) {
                @include span(12);
            }
        }
        .article-meta {
            @include span(4);
            color: #111;
            h6 {
                margin-bottom: .25rem;
                color: #999;
                font-weight: 400;
                text-transform: uppercase;
                font-weight: 600;
                letter-spacing: .1rem;
                &:first-child {
                    margin-top: 0;
                }
            }
            @include screen(0px, 768px) {
                display: none;
            }
        }
        .main-image {
            @include maintain-ratio(16 9);
            overflow: hidden;
            margin: 0 0 2rem 0;
            img {
                max-width: 100%;
                min-width: 100%;
                margin: 0;
            }
        }
        b {
            font-weight: 500;
        }
    }
    .article-content {
        @include span(8);
        padding-bottom: 3rem;
        h4 {
            margin-top: 2rem;
            margin-bottom: 1rem;
        }
        .article-image {
            @include maintain-ratio(16 9);
            overflow: hidden;
            margin: 2rem 0;
            img {
                max-width: 100%;
                min-width: 100%;
            }
        }
        h5 {
            font-weight: 500;
            margin-bottom: .75rem;
        }
        blockquote {
            margin: 2rem 0 2rem 2rem;
            font-family: "Georgia";
            font-style: italic;
            font-weight: bold;
            line-height: 1.25;
            font-size: $font-size-h3;
            position: relative;
            p {
                font-size: inherit;
            }
            &:before {
                content: "\201C";
                position: absolute;
                left: -2rem;
                top: 0;
                font-size: $font-size-h1;
                color: #aaa;
            }
        }
        @include max-screen(768px) {
            @include span(12);
        }
    }
    .article-meta-mobile {
        @include span(12);
        border-top: 2px solid #eee;
        padding-top: 2rem;
        text-align: center;
        h6 {
            margin-bottom: .25rem;
            color: #999;
            font-weight: 400;
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: .1rem;
            &:first-child {
                margin-top: 0;
            }
        }
        @include min-screen(768px) {
            display: none;
        }
    }
    .article-sidebar {
        @include span(4);
        text-align: right;
        border-left: 1px solid #ddd;
        margin-bottom: 2rem;
        h5 {
            font-weight: 500;
            margin-top: 0;
            margin-bottom: .5rem;
            text-transform: uppercase;
            font-size: $font-size-h3/2;
        }
        @include max-screen(768px) {
            @include span(12);
            border: none;
            text-align: center;
        }
    }
}
