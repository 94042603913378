@include with-layout(12 inside) {
    .partner {
        @include span(3);
        margin-bottom: vr(ms(0));
        .partner-inner {
            padding: 1rem;
            background: #fff;
            box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, .2);
            cursor: pointer;
            &:hover {
                box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, .1);
            }
            //border: 1px solid #eee;
        }
        .partner-img {
            display: block;
            position: relative;
            width: 100%;
            @include maintain-ratio(4 4);
            text-align: center;
            img {
                max-width: 100%;
                min-width: 100%;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }
        @include max-screen(768px) {
            @include span(6);
        }
    }
}
