.speaker {
    @include span(3);
    border: 1px solid #eee;
    margin-bottom: gutter()*2;
    @include max-screen(768px) {
        @include span(12);
    }
    a {
        display: block;
        width: 100%;
        height: auto;
        color: #111;
        box-shadow: 0px 0px 2px 0px rgba(0,0,0,.2);
        &:hover {
            box-shadow: 0px 0px 2px 3px rgba(0,0,0,.1);
        }
    }
    .speaker-image {
        @include maintain-ratio(4 4);
        overflow: hidden;
        img {
            max-width: 100%;
            min-width: 100%;
        }
    }
    .speaker-info {
        padding: gutter()*3;
        text-align: center;
        h5 {
            margin: 0 0 .25rem 0;
            text-transform: uppercase;
            //word-spacing: 9999px;
            font-weight: 500;
        }
        p {
            margin: 0 0 .5rem 0;
            display: block;
            font-size: $font-size-h5;
            color: #666;
        }
        small {
            border: 1px solid #aaa;
            margin: 0;
            display: inline-block;
            color: #aaa;
            padding: gutter()/2 gutter()*2;
            font-weight: 500;
        }
    }
}