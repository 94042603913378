.user-navigation-container {
    background: #333;
    width: 100%;
    height: vr(ms(2));
    line-height: vr(ms(2));
    font-weight: 500;
    .container {
    	position: relative;
    }
}

.user-navigation-name {
    color: #fff;
    float: left;
}

nav.user-navigation {
	position: absolute;
	right: 0;
    list-style: none;
    li {
        float: left;
        margin-right: 1rem;
        &:last-child {
        	margin-right: 0rem;
        }
        a {
            color: #ccc;
        }
    }
}
