footer {
	background: #f3f3f3;
	padding: 2rem 0;
	.container {
		position: relative;
		height: 100%;
		line-height: 1;
	}
	.footer-logo {
		@include span(2);
		img {
			max-width: 100%;
			filter: invert(0);
		}
	}
	.footer-nav {
		@include span(10 last);
		height: 100%;
		ul {
			list-style: none;
			float: right;
			line-height: 1;
			li {
				float: left;
				margin-left: 1rem;
				a {
					color: #666;
					text-decoration: none;
					&:hover {
						color: #111;
					}
				}
			}
		}
	}
}