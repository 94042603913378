@include with-layout(12 fluid after) {
    .day-row {
        @include span(12 no-gutters);
        border-bottom: 1px solid #eee;
        padding: .5rem gutter()/2;
        .day-title {
            font-size: ms(2);
            @include span(2);
            padding-top: 1rem;
            font-weight: bold;
	        @include max-screen(768px) {
				@include span(12);
				font-size: ms(4);
				margin-bottom: 2rem;
	        }
        }
        .day-items {
            @include span(10 last);
	        @include max-screen(768px) {
				@include span(12);
	        }
            .day-item {
                @include span(12);
                border-bottom: 1px solid #eee;
                padding: 1rem 0;
                &:last-child {
                    border: 0;
                }
                .day-item-time {
                    @include span(2) padding-top: .25rem;
                    border-right: 2px solid #111;
                    padding-right: .5rem;
			        @include max-screen(768px) {
						@include span(12);
						border: 0;
						span {
							float: left;
							margin-right: .5rem;
							&:last-child {
								position: relative;
								padding-left: .75rem;
								&:before {
									content: '-';
									position: absolute;
									left: 0;
									top: 0;
									background: "#111";
								}
							}
						}
			        }
                    span {
                        display: block;
                        text-align: right;
                    }
                }
                .day-item-title {
                    @include span(7);
                    h3 {
                        margin: 0;
                    }
			        @include max-screen(768px) {
						@include span(12);
			        }
                }
                .day-item-sal {
                	@include span(3);
                	@include omega();
			        @include max-screen(768px) {
						@include span(12);
			        }
                }
            }
        }
    }
}
