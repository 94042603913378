.c_mobile--usernav {
	position: fixed;
	top: vr(ms(4));
	left: 0;
	display: block;
	min-height: 100vh;
	height: 100%;
	width: 100%;
	background: #111;
	border-top: 1px solid rgba(255,255,255,.1);
	z-index: 1000;
	color: #fff;
	pointer-events: none;
	opacity: 0;
	a {
		color: #fff;
		text-decoration: none;
	}
	.btn {
		font-weight: 600;
		border-radius: 0px;
	}
	.btn-dark {
		border: 1px solid rgba(255,255,255,.1);
		color: #aaa;
		&:first-child{
			border-top-left-radius: 2px;
			border-bottom-left-radius: 2px;
		}
		&:nth-child(2) {
			border-left: 0px;
			border-right: 0px;
		}
		&:last-child{
			border-top-right-radius: 2px;
			border-bottom-right-radius: 2px;
		}
	}
	.btn-dark.active {
		color: #fff;
		background: rgba(255,255,255,0.05)
	}
	form {
		label {
			font-weight: 400;
		}
		input {
			border-radius: 2px;
		}
		.btn{
			border: 1px solid #fff;
			border-radius: 2px;
			background: #fff;
			color: #111;
			font-weight: 600;
		}
	}
}

.forgot-password-link {
	display: inline-block;
	padding-top: 7px;
}