section {
	padding-bottom: 3rem;
	.section-header {
		position: relative;
		text-align: left;
		h2 {
			display: inline-block;
			float: left;
			margin-top: 0;
			text-transform: uppercase;
		}
		h3 {
			display: inline-block;
			float: left;
			margin-top: 0;
			@include max-screen(768px) {
				display: block;
			}
		}
		.btn {
			position: absolute;
			right: 0;
			display: inline-block;
			border: 1px solid #aaa;
			color: #666;
			font-weight: normal;
			margin-top: 0.4rem;
			&:hover {
				background: #f7f7f7;
				color: #111;
			}
		}
		&.small {
			h2 {
				font-size: $font-size-h3;
				color: #666;
			}
		}
	}
	@include max-screen(768px) {
		.section-header {
			@include span(12);
			text-align: center;
			h3 {
				@include span(12);
				margin-bottom: .25rem;
			}
			h2 {
				margin-bottom: .25rem;
				@include span(12);
			}
			.btn {
				position: relative;
				@include span(12 no-margin);
			}
		}
	}
}
