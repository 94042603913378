$susy: ( columns: 12, gutters: 1, math: fluid, output: float, gutter-position: split, global-box-sizing: border-box);

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    margin: 0;
    //padding: 0 0 4rem 0;
    background: #fff;
}

body.signed-in {
    padding-top: vr(ms(2));
}

a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

img {
    max-width: 100%;
}

main.home {
    //padding-top: ms(vr(4));
}

.container {
    @include container(1140px);
    @include screen(0px, 768px) {
        @include container(92.5%);
    }
}

.container.maincontainer {
    @include container(1140px);
    padding-bottom: vr(ms(4));
    @include screen(0px, 768px) {
        @include container(92.5%);
    }
}

.btn-group {
    @include span(12);
    label.btn.btn-default.active {
        position: relative;
        display: block;
        background: transparent;
        padding: 0;
        margin: 0;
        box-shadow: 0;
        height: 0px;
        border: 0;
        z-index: 400;
        transition: all 175ms ease-in-out;
        .btn.btn-default {
            transition: all 175ms ease-in-out;
            box-shadow: 0px 0px 1px 1px rgba(#333,.175);
            z-index: 800;
            background: #f3f3f3;
        }
        @include max-screen(768px) {
            height: auto;
        }
    }
    .btn {
        display: block;
        width: 100%;
        clear: both;
        transition: all 175ms ease-in-out;
        &.btn-default.active {
            transition: all 175ms ease-in-out;
            background: #f3f3f3;
            box-shadow: 0px 0px 1px 1px rgba(#333,.175);
        }
        &:hover {
            transition: all 175ms ease-in-out;
            background: #eee;
            box-shadow: 0px 0px 1px 1px rgba(#333,.175);   
        }
    }
    @include min-screen(768px) {
        display: flex;
        .btn {
            flex-grow: 1;
            margin-bottom: .5rem;
        }
    }
}

.btn-group-justified {
    @include span(12);
    label.btn.btn-default.active {
        position: relative;
        display: block;
        background: transparent;
        padding: 0;
        margin: 0;
        box-shadow: 0;
        height: 0px;
        border: 0;
        z-index: 400;
        transition: all 175ms ease-in-out;
        .btn.btn-default {
            transition: all 175ms ease-in-out;
            box-shadow: 0px 0px 1px 1px rgba(#333,.175);
            z-index: 800;
            background: #f3f3f3;
        }
        @include max-screen(768px) {
            height: auto;
        }
    }
    .btn {
        display: block;
        width: 100%;
        clear: both;
        transition: all 175ms ease-in-out;
        &.btn-default.active {
            transition: all 175ms ease-in-out;
            background: #f3f3f3;
            box-shadow: 0px 0px 1px 1px rgba(#333,.175);
        }
        &:hover {
            transition: all 175ms ease-in-out;
            background: #eee;
            box-shadow: 0px 0px 1px 1px rgba(#333,.175);   
        }
    }
    @include min-screen(768px) {
        display: flex;
        .btn {
            flex-grow: 1;
            margin-bottom: .5rem;
        }
    }
}

.page-body {
    margin-top: vr(ms(2));
    padding-bottom: 2rem;
}

.section-header {
    @include span(12);
    padding-bottom: vr(ms(0));
    text-align: center;
    position: relative;
    //margin-bottom: vr(ms(0));
    // &:after {
    //   position: absolute;
    //   left: gutter();
    //   bottom: 0;
    //   content: '';
    //   background: #111;
    //   height: 2px;
    //   width: 100% - gutter()*2;
    //   display: block;
    // }
}

.section-bottom {
    @include span(12);
    text-align: center;
    padding: 0 0 vr(ms(2)) 0;
}

hr {
    border: 1px solid #ddd;
}
