.news {
    @include span(12);
}

.news-page {
    @include span(12);
}

.news-item {
    @include span(12);
    margin: 0 0 vr(ms(1)) 0;
    background: #fff;
    //box-shadow: 0px 2px 2px 0px rgba(0,0,0,.15);
    border-radius: 2px;
    @include min-screen(768px) {
        @include span(4);
        &:nth-child(3n+3) {
            @include omega();
        }
    }
}

.news-item-lg {
    @include span(12);
    margin: 0 0 vr(ms(1)) 0;
    background: #fff;
    //box-shadow: 0px 2px 2px 0px rgba(0,0,0,.15);
    border-radius: 2px;
    @include min-screen(768px) {
        @include span(6);
        &:nth-child(2n+2) {
            @include omega();
        }
    }
}

.news-item-content {
    padding: ms(-2) ms(0);
    h4 {
        display: block;
        margin: vr(ms(0))/2 0;
    }
}

.news-image {
    @include maintain-ratio(16 9);
    width: 100%;
    background: #aaa;
}

.news-item-footer {
    @include span(12);
    border-top: 1px solid #eee;
    padding: 0 -gutter();
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            display: inline-block;
            border-right: 1px solid #eee;
            padding: ms(1)/2 ms(1);
            a {
                color: #666;
                margin: 0;
                font-weight: 500;
                &:before {
                    color: #aaa;
                }
            }
        }
        @include min-screen(768px) {
            li {
                border-right: 0px;
            }
        }
    }
}

.news-item-page {
    @include span(4);
    @include max-screen(768px) {
        @include span(12);
    }
    .news-item-title {
        @extend h4;
        display: block;
        font-weight: 600;
        margin-bottom: .5rem;
        color: #111;
        &:hover {
            color: #666;
        }
    }
    .news-item-page-img {
        position: relative;
        @include maintain-ratio(16 9);
        overflow: hidden;
        img {
            max-width: 100%;
            min-width: 100%;
        }
    }
    .news-item-featured-marker {
        position: absolute;
        left: gutter()*2;
        padding: gutter()/2 gutter()*2;
        top: 0;
        background: #111;
        color: #fff;
        font-size: $font-size-h6;
        font-weight: 500;
        text-transform: uppercase;
    }
    small {
        color: #aaa;
        font-weight: 500;
        display: block;
    }
    p {
        display: block;
        margin-top: 1rem;
        color: #666;
        margin-bottom: 4rem;
    }
    .readmore {
        font-size: $font-size-h6;
        color: #aaa;
        font-weight: 600;
        display: none;
        text-transform: uppercase;
        &:hover {
            color: #111;
        }
    }
}

.news-item-page.lg {
    @include span(8);
    margin-bottom: 0rem;
    @include max-screen(768px) {
        @include span(12);
        margin-bottom: 2rem;
    }
}

.paginate {
    border-top: 1px solid #eee;
    //border-bottom: 1px solid #eee;
    margin: 0;
    padding: 0;
    line-height: 0;
    @include max-screen(768px) {
        margin-top: vr(ms(2));
    }
}

.pagination {
    padding: 0 gutter();
    margin: 0;
    font-weight: 500;
    li {
        a {
            border-radius: 0px;
            border-top: 0px;
            border-bottom: 0px;
        }
    }
}
