.socialmedia {
	position: fixed;
	top: 0;
	bottom: 0;
	z-index: 9999;
	height: 100vh;
	background: transparent;
	ul.social_media {
		position: absolute;
		list-style: none;
		padding: 0;
		padding-left: .5rem;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		li {
			font-size: $font-size-h4;
			text-align: center;
			padding: .5rem;
			a {
				color: #666;
			}
		}
	}
	@include max-screen(768px) {
		display: none;
	}
}