@mixin maintain-ratio($ratio: 1 1) {
  @if length($ratio) < 2 or length($ratio) > 2 {
    @warn "$ratio must be a list with two values.";
  }

  $width: 100%;
  $height: percentage(nth($ratio, 2) / nth($ratio, 1));
  
  width: $width;
  height: 0;
  padding-bottom: $height;
}