.btn-inverse {
    background: #F36B39; //#FBA026
    color: #fff;
    font-weight: 500;
    width: auto;
    &:hover {
    	color: #fff;
    }
}

.btn-fw {
    width: 100%;
}

.btn {
    font-weight: 600;
}

.btn {
	background: #F36B39 !important;
	color: #fff !important;
	border: 0 !important;
}

button {
	background: #F36B39 !important;
	color: #fff !important;
}