header.home {
    background: #666;
    position: relative;
    left: 0;
    top: 0;
    height: 768px;
    min-height: 640px;
    width: 100%;
    overflow: hidden;
    a {
        color: #fff;
        &:hover {
            color: #fff;
        }
    }
    .header-home-inner {
        position: relative;
        width: 100%;
        height: 100%;
        background-image: url('../images/amsterdambg.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center 80%;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            height: 400px;
            width: 100%;
            background: linear-gradient(0deg, rgba(0, 0, 0, .8), rgba(0, 0, 0, 0));
            z-index: 20;
        }
        .container {
            height: 100%;
            width: 100%;
            position: relative;
            z-index: 50;
            -webkit-filter: drop-shadow(0 2px 20px rgba(0, 0, 0, .4));
            .hero {
                @include span(10);
                @include pre(1);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                text-align: center;
                //margin-top: -2rem;
                h1 {
                    font-size: 4rem;
                    font-weight: bold;
                    color: #fff;
                    //max-width: 25ch;
                    //margin: 0 auto;
                    //letter-spacing: .25rem;
                    text-transform: uppercase;
                    margin-bottom: 1rem;
                    text-shadow: 0 0 5px rgba(0, 0, 0, .25);
                }
                h4 {
                    font-weight: normal;
                    color: #fff;
                    text-shadow: 0 0 5px rgba(0, 0, 0, 1);
                    -webkit-filter: drop-shadow(0 2px 2px rgba(0, 0, 0, .25));
                }
                i {
                    padding-right: .75rem;
                    color: #fff;
                    &:last-child {
                        margin-left: 1.5rem;
                    }
                }
                .info-1 {
                        margin-bottom: 3rem;
                    ul {
                        //display: flex;
                        list-style: none;
                        padding: 0;
                        text-shadow: 0 0 5px rgba(0, 0, 0, .5);
                        li {
                            @extend h2;
                            font-weight: 600;
                            flex-grow: 1;
                            text-align: center;
                            color: #fff;
                            display: inline;
                            padding: 0 1rem;
                            border-right: 2px solid rgba(255, 255, 255, .75);
                            &:last-child {
                                border-right: 0;
                            }
                        }
                    }
                }
                .btn {
                    background: #EF4135;
                    color: #fff;
                    border: 0;
                    border-radius: 2px;
                    padding: 1rem 2rem;
                    font-size: $font-size-h3*0.8;
                    margin: 0 .5rem;
                    i {
                        margin-left: 0;
                    }
                    @include max-screen(768px) {
                        width: auto;
                        clear: both;
                        margin-bottom: 1rem;
                        font-size: $font-size-h5*1.25;
                        padding: 0.5rem 1rem;
                    }
                }
                @include screen(0px, 480px) {
                    top: 30%;
                    transform: translateY(-30%);
                    h1 {
                        font-size: 1.75rem;
                    }
                    h4 {
                        font-size: $font-size-h5;
                    }
                    .info-1 {
                        display: none;
                        ul {
                            li {
                                display: block;
                                border: 0;
                                font-size: $font-size-h4;
                            }
                        }
                    }
                }
                @include screen(480px,
                768px) {
                    @include span(12);
                    @include pre(0);
                    top: 30%;
                    transform: translateY(-30%);
                    h1 {
                        font-size: 4vw;
                    }
                    h4 {
                        font-size: $font-size-h5;
                    }
                    .info-1 {
                        ul {
                            li {
                                //display: block;
                                //border: 0;
                                font-size: $font-size-h4;
                            }
                        }
                    }
                }
                @include screen(768px,
                1065px) {
                    h1 {
                        font-size: $font-size-h4;
                    }
                    h4 {
                        font-size: $font-size-h5*1.5;
                    }
                    .info-1 {
                        ul {
                            li {
                                font-size: $font-size-h4*1.5;
                            }
                        }
                    }
                }
                @include screen(768px,
                1280px) {
                    @include span(12);
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
}
