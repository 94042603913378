.c_top-bar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 900;
    height: vr(ms(4)) + vr(ms(1));
    line-height: vr(ms(4));
    width: 100%;
    background: #fff;
    color: #fff;
    border-bottom: 2px solid #eee;
    transition: transform 200ms linear;
            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 66px;
                height: 66px;
                background: url('/sites/all/themes/rde/images/amsterdamflag.png') no-repeat;
                background-size: 100%;
            }
    .stripes {
        display: none;
    }
    a {
        color: #111;
    }
    .menu-btn.oi {
        color: #fff;
        &:before {
            border: 1px solid #000;
            background: #111;
            color: #fff;
            font-weight: bold;
            padding: 1rem;
            border-radius: 999px;
        }
    }
    //@include ms-respond(font-size, 2);
    .container {
        @include container(1140px);
        position: relative;
        padding: 0 gutter()/2;
    }
    @include max-screen(768px) {
        height: vr(ms(4)) + vr(ms(1));
        line-height: vr(ms(4));
        background: #fff;
        border: 0;
        box-shadow: 0px 2px 2px 0px rgba(0,0,0,.175);
        a {
            color: #111;
        }
    }
    .top {
        width: 100%;
        height: vr(ms(1));
        line-height: vr(ms(1));
        background: #111;
        font-size: $font-size-h2/3;
        font-weight: 500;
        color: rgba(255, 255, 255, .4);
        border-bottom: 1px solid #333;
        transition: transform 200ms linear;
        &.home {
            background: rgba(0, 0, 0, .15);
            border-bottom: 0px;
            .top-inner {
                ul.sign-in {
                    li {
                        border-right: 1px solid rgba(0, 0, 0, .1);
                        &:first-child {
                            border: 0px;
                        }
                        &:nth-child(2) {
                            border-left: 1px solid rgba(0, 0, 0, .1);
                        }
                    }
                }
                ul.lang-picker {
                    background: transparent;
                    li {
                        margin-right: 0;
                        margin-left: 0.5rem;
                        &:first-child {
                            margin: 0;
                        }
                    }
                }
            }
        }
        .top-inner {
            @include span(12 no-gutters);
            margin: 0 gutter()*1.5;
        }
        a {
            color: #fff;
        }
        @include max-screen(768px) {}
        .lang-picker-toggle {
            float: right;
            color: rgba(255, 255, 255, .75);
            text-transform: uppercase;
            &:hover {
                text-decoration: none;
                color: #fff;
            }
            &:focus {
                text-decoration: none;
            }
        }
        ul.lang-picker {
            float: right;
            padding: 0 0 0 .5rem;
            margin: 0 0 0 1rem;
            list-style: none;
            transition: ease-in-out 250ms;
            background: #666;
            li {
                border: 0;
                display: inline-block;
                margin-right: 1em;
                line-height: normal;
                padding: .1rem;
                background: transparent;
                .flag-icon {
                    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, .175);
                }
            }
        }
        ul.sign-in {
            float: right;
            //right: gutter();
            padding-left: 0;
            margin: 0;
            list-style: none;
            text-transform: uppercase;
            @include max-screen(768px) {
                display: none;
            }
            li {
                float: left;
                //border-right: 1px dotted #333;
                &:first-child {
                    border: none;
                    margin-right: 1rem;
                }
                &:nth-child(2) {
                    //border-left: 1px dotted #333;
                }
                a {
                    display: block;
                    padding: 0 1rem;
                    color: rgba(255, 255, 255, .75);
                    &:hover {
                        color: #fff;
                        background: #333;
                    }
                }
            }
        }
    }
    .menu-btn {
        position: absolute;
        left: gutter();
        cursor: pointer;
        z-index: 20;
        font-weight: 600;
        &:before {
            display: inline-block;
            margin-right: .25rem;
        }
        @include min-screen(1065px) {
            display: none;
        }
    }
    .login-btn {
        position: absolute;
        right: 0;
        cursor: pointer;
        z-index: 20;
        @include min-screen(1065px) {
            display: none;
        }
    }
    .logo {
        position: relative;
        float: left;
        margin-left: gutter()/6;
        img {
            max-width: 120px;
            margin-top: -2px;
        }
        @include max-screen(768px) {
            img {
            }
        }
        @include max-screen(1065px) {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    nav.main-desktop {
        position: absolute;
        right: gutter();
        display: inline-block;
        list-style: none;
        margin-left: 2rem;
        font-weight: 500;
        text-transform: uppercase;
        font-size: $font-size-h5;
        .container {
            position: relative;
            height: 100%;
        }
        li {
            float: left;
            margin-right: 1rem;
            &:last-child {
                margin-right: 0;
                margin-left: 1rem;
            }
            a {
                &:hover {
                    color: #666;
                }
            }
        }
        li.active {
            position: relative;
            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 1rem;
                width: 100%;
                height: 2px;
                background: rgba(0, 0, 0, 1);
            }
            a {
                &:hover {
                    color: #111;
                }
            }
        }
        .dropdown {
            float: left;
            display: inline-block;
            cursor: arrow;
            .dropdown-toggle {
                cursor: pointer;
                &:hover {
                    color: #666;
                }
            }
        }
        .dropdown-menu {
            top: 50px;
            border: 0;
            background: rgba(0, 0, 0, .95);
            font-size: $font-size-h6;
            li {
                display: block;
                margin: 0;
                padding: 0;
                width: 100%;
                &:hover {
                    background: red;
                }
                a {
                    display: block;
                    color: #fff;
                    font-weight: 500;
                    &:hover {
                        background: #eee;
                        color: #111;
                    }
                }
            }
        }
        @include max-screen(1065px) {
            display: none;
        }
    }
}

.c_top-bar--home {
    background: #000;
    border: 0;
    height: 8rem;
    line-height: 6rem;
    box-shadow: 0px 2px 2px 0px rgba(0,0,0,0);
    .stripes {
        position: absolute;
        top: 0rem;
        left:-3rem;
        max-height: 100%;
        img {
            margin: 0;
        }
    }
    &:before {
        content: '';
        position: fixed;
        left: -80px;
        top: -6px;
        width: 180px;
        height: 130px;
        background: url("../images/stripes2.png");
        background-size: cover;
        //z-index: 9999;
    }
    //transition: transform 350ms ease-in-out, background 350ms ease-in-out;
    a {
        color: #fff;
        &:hover {
            color: #F36B39 !important;
        }
    }
    nav.main-desktop {
        li {
            a {
                color: #fff;
                &:hover {
                    color: #111;
                }
            }
        }
        .dropdown {
            float: left;
            display: inline-block;
            cursor: arrow;
            .dropdown-toggle {
                cursor: pointer;
                &:hover {
                    color: #111;
                }
            }
        }
    }
    .logo {
        img {
            transform: scale(1.25);
            //transform-origin: left center;
        }
    }
}

.c_top-bar--home.headroom--top {
    line-height: 5rem;
}

.c_top-bar.headroom--not-top {
    transform: translateY(-1.875rem);
    transition: transform 350ms ease-in-out, background 350ms ease-in-out;
    background: #fff;
    height: vr(ms(4)) + vr(ms(1));
    line-height: vr(ms(4));
    overflow: hidden;
    box-shadow: 0px 2px 2px 0px rgba(0,0,0,.175);
    .stripes {
        position: absolute;
        top: 1.8rem;
        left:-3rem;
    }
    a {
        color: #111;
    }
    nav.main-desktop {
        li {
            a {
                color: #111;
            }
        }
        .dropdown-menu {
            top: 50px;
            border: 0;
            background: rgba(0, 0, 0, .95);
            font-size: $font-size-h6;
            li {
                display: block;
                margin: 0;
                padding: 0;
                width: 100%;
                &:hover {
                    background: red;
                }
                a {
                    display: block;
                    color: #fff;
                    font-weight: 500;
                    &:hover {
                        background: #eee;
                        color: #111;
                    }
                }
            }
        }
    }
    .logo {
        img {
            transform: scale(1);
            transition: transform 125ms ease-in-out;
        }
    }
}

.c_top-bar--home.headroom--not-top {
    transform: translateY(-1.875rem);
    transition: transform 350ms ease-in-out, background 350ms ease-in-out;
    background: #000;
    height: vr(ms(4)) + vr(ms(1));
    line-height: vr(ms(4));
    overflow: hidden;
    box-shadow: 0px 2px 2px 0px rgba(0,0,0,.175);
    .stripes {
        position: absolute;
        top: 1.8rem;
        left:-3rem;
    }
    a {
        color: #fff;
    }
    nav.main-desktop {
        li {
            a {
                color: #fff;
            }
        }
        .dropdown-menu {
            top: 50px;
            border: 0;
            background: rgba(0, 0, 0, .95);
            font-size: $font-size-h6;
            li {
                display: block;
                margin: 0;
                padding: 0;
                width: 100%;
                &:hover {
                    background: red;
                }
                a {
                    display: block;
                    color: #fff;
                    font-weight: 500;
                    &:hover {
                        background: #eee;
                        color: #111;
                    }
                }
            }
        }
    }
    .logo {
        img {
            transform: scale(1);
            transition: transform 125ms ease-in-out;
        }
    }
}

.dropdown-menu-login {
    background: #111;
    top: vr(ms(4));
    margin-top: 0;
}

.dropdown-menu {
    .container {
        @include container(100%);
    }
}

// .c_top-bar--home {
//  background: rgba(#000, .1);
//  border-bottom: 1px solid rgba(#000, .2);
//  .menu-btn {
//      border-right: 1px solid rgba(#000, .2);
//      padding-right: .75em;
//  }
//  .login-btn {
//      border-left: 1px solid rgba(#000, .2);
//      padding-left: .75em;
//  }
// }
